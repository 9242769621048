var searchKeys = [{
  key: "name",
  label: "品牌名称",
  placeholder: "中文名称/英文名称",
  required: false,
  rules: [],
  select: true
}, {
  key: "status",
  label: "启用/停用",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "searchterm",
  label: "搜索词",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };