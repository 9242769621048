var columns = [{
  title: '品牌logo',
  dataIndex: 'imgurl',
  key: 'imgurl',
  // width: '10%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '英文名称',
  dataIndex: 'name',
  key: 'name',
  // width: '14%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '中文名称',
  dataIndex: 'cnname',
  key: 'cnname',
  // width: '10%',
  scopedSlots: {
    customRender: 'cnname'
  }
}, {
  title: '搜索词',
  dataIndex: 'searchterm',
  key: 'searchterm',
  // width: '14%',
  scopedSlots: {
    customRender: 'searchterm'
  },
  ellipsis: true
}, {
  title: '类目',
  dataIndex: 'brandCategoryRefList',
  key: 'brandCategoryRefList',
  // width: '20%',
  scopedSlots: {
    customRender: 'brandCategoryRefList'
  },
  ellipsis: true
}, {
  title: '备注',
  dataIndex: 'desc',
  key: 'desc',
  // width: '11%',
  scopedSlots: {
    customRender: 'desc'
  },
  ellipsis: true
}, {
  title: '停用/启用',
  dataIndex: 'status',
  key: 'status',
  // width: '10%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };